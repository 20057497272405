import React from "react";
import { NavDropdown } from "react-bootstrap";
import "video-react/dist/video-react.css"; // import css
import { Link } from 'react-router-dom'


class EgitimMenuOgesi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
      egitimlerimiz_isloaded: false,
      egitimlerimiz_error: "yok",
      egitimlerimiz: [],
    };
    fetch(process.env.PUBLIC_URL + "/JSON/egitimlerimiz.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          egitimlerimiz_isloaded: true,
          egitimlerimiz: result.egitimlerimiz
        });
      },
      (error) => {
        this.setState({
          egitimlerimiz_isloaded: true,
          egitimlerimiz_error: "Hata var " + error
        });
      }
    )
  }
  /*
  verilericek() {
    fetch("JSON/egitimlerimiz.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          egitimlerimiz_isloaded: true,
          egitimlerimiz: result.egitimlerimiz
        });
      },
      (error) => {
        this.setState({
          egitimlerimiz_isloaded: true,
          egitimlerimiz_error: "Hata var " + error
        });
      }
    )
  }

  componentDidMount() {
    this.verilericek();
    this.timer = setInterval(() => this.verilericek(), 5000);
    this.setState({ height: window.innerHeight });
  }
  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  }
*/

  render() {
    const { egitimlerimiz_isloaded, egitimlerimiz_error, egitimlerimiz, } = this.state;

    return (
      <>
        {egitimlerimiz_isloaded && egitimlerimiz_error === "yok" ?
          (
            <>
              {egitimlerimiz.map((m, i) => {
                if (!m.gizli) return (
                  <NavDropdown.Item key={"egitimmenuogesi_egitim" + i} eventkey={i + 30} as={Link} to={"/egitimlerimiz/" + m.id} href="#">
                    <div className="row align-items d-flex py-1">
                      <div className="col-lg-3 col-md-3 col-xl-3 col-4 pr-0">
                        <div className="rounded">
                          <img src={"/" + m.resimmenu + "?v=" + this.props.versiyon} className="rounded menuresmi" alt="Eğitmen Resmi" align="right" />
                        </div>
                      </div>
                      <div className="col-lg-9 col-md-9 col-xl-9 col-8 pl-2 text-truncate">
                        <span>{m.isim}</span>
                      </div>
                    </div>
                  </NavDropdown.Item>);
                else
                  return "";
              })}
            </>
          )
          :
          (
            <>
              {egitimlerimiz_error === "yok" ? (<div>Yükleniyor</div>) : (<div>Hata:{egitimlerimiz_error}</div>)}
            </>
          )
        }

      </>
    );
  }
}

export default EgitimMenuOgesi;
