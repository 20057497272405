import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { FaInstagram, FaFacebook, FaLinkedin, FaTwitter, FaYoutube, FaLock } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styled from "styled-components";

const SLink = styled(Link)`
  color: white;
  text-decoration: none;
  margin: 0.1rem;
  position: relative;
`;

class Enalt extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            height: 0,

            kurumsal_isloaded: false,
            kurumsal_error: "yok",
            kurumsal: [],
        };
        fetch("JSON/kurumsal.json").then(res => res.json()).then(
            (result) => {
                this.setState({
                    kurumsal_isloaded: true,
                    kurumsal: result.kurumsal
                });
            },
            (error) => {
                this.setState({
                    kurumsal_isloaded: true,
                    kurumsal_error: "Hata var " + error
                });
            }
        )
    }
    render() {
        const { kurumsal_isloaded, kurumsal_error, kurumsal, } = this.state;

        return (
            <>
                <Container>
                    <div className="egitimlerimizcizgidiv" />
                    <footer className="pt-2 my-md-2 pt-md-4 text-center">
                        <Row>
                            <Col xs={12} md={4}>
                                <h5>Keşfet</h5>
                                <ul className="list-unstyled text-small">
                                    <li><SLink to="/egitimlerimiz">Eğitimlerimiz</SLink></li>
                                    <li><SLink to="/egitmenlerimiz">Eğitmenlerimiz</SLink></li>
                                </ul>
                            </Col>
                            <Col xs={12} md={4}>
                                <h5>Kurumsal</h5>
                                <ul className="list-unstyled text-small">
                                    {kurumsal_isloaded && kurumsal_error === "yok" ?
                                        (
                                            <>
                                                {kurumsal.map((m, i) => {
                                                    if(m.tur==="sayfa") return (<li key={"enalt_kurumsal" + i}><SLink eventkey={i + 130} to={"/kurumsal/"+ m.id}>{m.baslik}</SLink></li>);
                                                    if(m.tur === "baglanti") return  (<li key={"enalt_kurumsal" + i}><a href={m.id} className="byzlnk">{m.baslik}</a></li>);
                                                    return "";
                                                })}
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                {kurumsal_error === "yok" ? (<div>Yükleniyor</div>) : (<div>Hata:{kurumsal_error}</div>)}
                                            </>
                                        )
                                    }
                                </ul>
                            </Col>

                            <Col xs={12} md={4} >
                                <h5>Sosyal Medya</h5>
                                <ul className="list-unstyled text-small">
                                    <li><a className="yazirengibeyaz" href="https://www.instagram.com/anadoluegitimakademisii" target={"_blank"} rel="noopener noreferrer"><FaInstagram /> Instagram</a></li>
                                    <li><a className="yazirengibeyaz" href="https://facebook.com/anadoluegitimakademisi" target={"_blank"} rel="noopener noreferrer"><FaFacebook /> Facebook</a></li>
                                    <li className="gizle"><a className="yazirengibeyaz" href="https://youtube.com" target={"_blank"} rel="noopener noreferrer"><FaYoutube /> Youtube</a></li>
                                    <li className="gizle"><a className="yazirengibeyaz" href="https://twitter.com" target={"_blank"} rel="noopener noreferrer"><FaTwitter /> Twitter</a></li>
                                    <li className="gizle"><a className="yazirengibeyaz" href="https://linkedin.com" target={"_blank"} rel="noopener noreferrer"><FaLinkedin /> Linkedin</a></li>
                                </ul>
                            </Col>
                        </Row>
                    </footer>
                    <div className="egitimlerimizcizgidiv" />

                    <Row className="text-center">
                        <Col xs={12} className="mb-2" >
                            <img src="/lwnc.png" className="altlogo" alt="hover" width="95%" />
                        </Col>
                        <Col xs={12} className="mb-4">
                            © 2022 Anadolu Eğitim Akademisi <br /><FaLock /> Secured with SSL
                        </Col>
                    </Row>
                </Container>
            </>

        );
    }
}

export default Enalt;
