import { Container, Row, Col } from "react-bootstrap";
import React from "react";
import { Link } from 'react-router-dom'
import Helmet from "react-helmet";

export default class Egitmenlerimiz extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sayfaseo_isloaded: false,
      sayfaseo_error: "yok",
      sayfaseo: [],

      egitmenlerimiz_isloaded: false,
      egitmenlerimiz_error: "yok",
      egitmenlerimiz: []
    };
    fetch(process.env.PUBLIC_URL + "/JSON/sayfaseo.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          sayfaseo_isloaded: true,
          sayfaseo: result
        });
      },
      (error) => {
        this.setState({
          sayfaseo_isloaded: true,
          sayfaseo_error: "Hata var " + error
        });
      }
    )
    fetch(process.env.PUBLIC_URL + "/JSON/egitmenlerimiz.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          egitmenlerimiz_isloaded: true,
          egitmenlerimiz: result.egitmenlerimiz
        });
      },
      (error) => {
        this.setState({
          egitmenlerimiz_isloaded: true,
          egitmenlerimiz_error: "Hata var " + error
        });
      }
    )
    this.aramaref = React.createRef();
  }


  render() {
    const { egitmenlerimiz_isloaded, egitmenlerimiz_error, egitmenlerimiz, sayfaseo } = this.state;


    return (
      <>
        <Helmet>
          <title>{sayfaseo.egitmenlerimiz_metatitle}</title>
          <meta name="description" content={sayfaseo.egitmenlerimiz_metadescription} />
          <meta name="keywords" content={sayfaseo.egitmenlerimiz_metakeywords} />

          <meta property="og:title" content={sayfaseo.egitmenlerimiz_metatitle} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="%PUBLIC_URL%/egitmenler" />
          <meta property="og:description" content={sayfaseo.egitmenlerimiz_metadescription} />
          <meta property="og:site_name" content="Anadolu Eğitim Akademisi" />
          <meta name="twitter:image:alt" content="Anadolu Eğitim Akademisi" />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>

        <section id="egitimlerimizlistesi" className="bg">
          <Container>
            <Row className="justify-content-center">
              <Col sm={10} className="text-center">
                <h4>Eğitmenlerimiz</h4>
              </Col>
            </Row>

            {/* Eğitimlerimizi Listele */}
            {egitmenlerimiz_isloaded ? (<>

              <Row>
                {egitmenlerimiz.map((m, i) => {
                  if (!m.gizli)
                    return (
                      <Col key={"Egitmenlerimiz_Listele" + i} xs={12} sm={12} md={6} lg={6} xl={3} xxl={3} className="text-center">
                        <Link to={"/egitmenlerimiz/" + m.id} className="yazirengibeyaz">
                          <div className="egitmendiv" >
                            <div>
                              <img className="rounded" src={m.resim + "?v=" + this.props.versiyon} alt={m.isim} width="80%" />
                            </div>
                            <div>
                              {m.isim}<br />
                              {m.unvan}
                            </div>
                          </div>
                        </Link>
                      </Col>
                    );
                  else
                    return "";
                }


                )}

              </Row>
            </>) : (<>

              {egitmenlerimiz_error === "yok" ? (<>
                <div>Eğitimlerimiz modülü yükleniyor, lütfen bekleyin.</div>
              </>) : (<div className="hata">SSS Modülü yüklenemedi...</div>)}</>)}
          </Container>

        </section>
      </>
    );
  }
}
