import React from "react";
import { Button, Modal } from "react-bootstrap";


class Kndlisteogesi extends React.Component {
    state = { detay: false };

    render() {
        const { detay } = this.state;

        return (
            <>
                <div className="knd">
                    {this.props.kursiyer} <br /> <em>{this.props.unvan} / {this.props.il}</em>

                    <img src={this.props.resim} className="kndresim" alt={this.props.kursiyer} />
                    <div className="kndaciklama">
                        {this.props.aciklamatam}
                    </div>
                    <Button variant="outline-light" className="my-3 ml-5" onClick={() => this.setState({ detay: true })}>Devamını Oku</Button>
                    <br />

                </div>

                <Modal show={detay} onHide={() => this.setState({ detay: false })} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.kursiyer}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body><img src={this.props.resim} className="kndmodalbody" alt={this.props.kursiyer} />{this.props.aciklamatam}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({ detay: false })}>Kapat</Button>
                    </Modal.Footer>
                </Modal>
            </>

        );
    }
}

export default Kndlisteogesi;
