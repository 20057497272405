import React from "react";
import { Route, Switch, withRouter, Link } from "react-router-dom";
import { Iletisim, AnaSayfa, AramaSonuclari, SayfaBulunamadi } from "./sayfalar";
import { Navbar, Nav, NavDropdown, Form, FormControl, Container, Offcanvas, Button, Col, Row } from "react-bootstrap";
import "./index.css";
import FloatingActionButton from "./API/fab"
import Enalt from "./API/enalt";
import EgitimMenuOgesi from "./API/egitimmenuogesi";
import EgitimDetay from "./sayfalar/EgitimDetay";
import Egitimlerimiz from "./sayfalar/Egitimlerimiz";
import { FaCreditCard, FaUser, FaSearch } from 'react-icons/fa';
import Egitmenlerimiz from "./sayfalar/Egitmenlerimiz";
import EgitmenDetay from "./sayfalar/EgitmenDetay";
import KurumsalMenuOgesi from "./API/kurumsalmenuogesi"
import Kurumsal from "./sayfalar/Kurumsal";
import Makalelerimiz from "./sayfalar/Makalelerimiz";
import MakaleDetay from "./sayfalar/MakaleDetay";

import { CSSTransition, TransitionGroup } from "react-transition-group";


class Ana extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      aramagorunumu: false,

      egitimlerimiz_isloaded: false,
      egitimlerimiz_error: "yok",
      egitimlerimiz: [],

      egitmenlerimiz_isloaded: false,
      egitmenlerimiz_error: "yok",
      egitmenlerimiz: [],

      kurumsal_isloaded: false,
      kurumsal_error: "yok",
      kurumsal: [],

      makalelerimiz_isloaded: false,
      makalelerimiz_error: "yok",
      makalelerimiz: [],
      versiyon: "bulunamadı"

    };
    fetch("JSON/egitimlerimiz.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          egitimlerimiz_isloaded: true,
          egitimlerimiz: result.egitimlerimiz
        });
      },
      (error) => {
        this.setState({
          egitimlerimiz_isloaded: true,
          egitimlerimiz_error: "Hata var " + error
        });
      }
    )

    fetch("JSON/egitmenlerimiz.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          egitmenlerimiz_isloaded: true,
          egitmenlerimiz: result.egitmenlerimiz
        });
      },
      (error) => {
        this.setState({
          egitmenlerimiz_isloaded: true,
          egitmenlerimiz_error: "Hata var " + error
        });
      }
    )

    fetch("JSON/kurumsal.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          kurumsal_isloaded: true,
          kurumsal: result.kurumsal
        });
      },
      (error) => {
        this.setState({
          kurumsal_isloaded: true,
          kurumsal_error: "Hata var " + error
        });
      }
    )


    fetch("JSON/makalelerimiz.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          makalelerimiz_isloaded: true,
          makalelerimiz: result.makalelerimiz
        });
      },
      (error) => {
        this.setState({
          makalelerimiz_isloaded: true,
          makalelerimiz_error: "Hata var " + error
        });
      }
    )

    fetch("versiyon.txt").then(res => res.text()).then(
      (result) => {
        this.setState({
          versiyon: result
        });
        console.log('Güncel versiyon:' + result);
      },
      (error) => {
        this.setState({
          versiyon: "1",
        });
        console.log('Versiyon bulunamadı');
      }
    )

    this.aramaref = React.createRef();
  }

  handleRoute = route => () => {
    this.props.history.push({ pathname: route });
  };

  handleShow = () => {
    this.setState({ aramagorunumu: true }, () => {
      this.aramaref.current.focus();
      this.aramaref.current.select();
    });
  };

  handleClose = () => {
    this.setState({ aramagorunumu: false });
  };

  enterSearchSubmit = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (this.aramaref.current.value.length >= 3) {
        this.setState({ aramagorunumu: false });
        this.props.history.push({
          pathname: "/aramasonuclari",
          state: {
            searchText: this.aramaref.current.value
          }
        });
      } else {
        alert("Arama yapmak için en az 3 karakter girin");
        this.props.history.push("/");
      }
    }
    else if (e.key === "Escape") {
      e.preventDefault();
      this.handleClose();
    }

  };



  render() {
    const { kurumsal, egitimlerimiz, egitmenlerimiz, makalelerimiz, versiyon } = this.state;
    const { location } = this.props;
    return (
      <>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="custom" className="navbar-dark" fixed="top">
          <Container>
            {/*BÜYÜK EKRANDA LOGO*/}
            <Navbar.Brand eventkey="1" as={Link} to="/" className="d-none d-lg-block ">
              <img alt="" src="logo.png" height="30" className="d-inline-block align-top" />{' '}<span className="buyukekranbrand">Anadolu Eğitim Akademisi</span>
            </Navbar.Brand>
            {/*KÜÇÜK EKRANDA LOGO*/}
            <Navbar.Brand className="d-block d-lg-none order-first">
              <Nav.Link eventkey="2" as={Link} to="/" href="#">
                <img alt="" src="logo.png" height="30" className="d-inline-block align-top" />{' '}
                <span className="kucukekranbrand byzlnk">Anadolu Eğitim Akademisi</span>
              </Nav.Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />

            <Navbar.Collapse id="navbarScroll">
              <Nav className="me-auto">
                <NavDropdown title="Eğitimlerimiz" id="egitimlerimizmenusu">
                  <EgitimMenuOgesi versiyon={versiyon} />
                </NavDropdown>
                <NavDropdown title="Kurumsal" id="collasible-nav-dropdown">
                  <KurumsalMenuOgesi />
                </NavDropdown>
                <Nav.Link eventkey="13" as={Link} to="/egitmenlerimiz" href="#">Eğitmenlerimiz</Nav.Link>

                <Nav.Link eventkey="14" as={Link} to="/blog" href="#">Blog</Nav.Link>

                <Nav.Link eventkey="15" as={Link} to="/iletisim" href="#">İletişim</Nav.Link>
              </Nav>
              <Nav id="sag" className="ml-auto d-none d-xl-block">
                <Nav.Link onClick={this.handleShow}> <FaSearch /> Arama </Nav.Link>
              </Nav>
              <Nav className="ml-auto d-none d-xl-block">
                <Nav.Link href="http://anadoluegitimakademisi.org/"> <FaUser /> Öğrenci Girişi </Nav.Link>
              </Nav>
              <Nav className="ml-auto d-none d-xl-block">
                <Nav.Link href="https://pos.param.com.tr/Tahsilat/Default.aspx?k=a00026b1-a4bb-4276-a2d3-398de0c5b334"> <FaCreditCard /> Online Ödeme </Nav.Link>
              </Nav>
            </Navbar.Collapse>
            <Offcanvas aria-labelledby="deneme" show={this.state.aramagorunumu} placement="top" >
              <Offcanvas.Header onHide={this.handleClose}>
                <Offcanvas.Title>Arama</Offcanvas.Title>
                <Button variant="outline-dark" onClick={this.handleClose}>X</Button>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Form className="d-flex outline-success me-50">
                  <FormControl
                    autoFocus
                    id="arama"
                    onKeyDown={(e) => this.enterSearchSubmit(e)}
                    type="text"
                    placeholder="Ara"
                    aria-label="Ara"
                    ref={this.aramaref}
                  />
                </Form>
              </Offcanvas.Body>
            </Offcanvas>
          </Container>
          <Container className="ml-auto d-block d-xl-none">
            <Nav>
              <Row>
                <Col xs={6} className="d-flex justify-content-center">
                  <Nav.Link href="http://anadoluegitimakademisi.org/"> <FaUser /> Öğrenci Girişi </Nav.Link>
                </Col>
                <Col xs={6} className="d-flex justify-content-center">
                  <Nav.Link href="https://pos.param.com.tr/Tahsilat/Default.aspx?k=a00026b1-a4bb-4276-a2d3-398de0c5b334"> <FaCreditCard /> Online Ödeme </Nav.Link>
                </Col>
              </Row>
            </Nav>
          </Container>
        </Navbar>
        <TransitionGroup>
          <CSSTransition
            key={location.key}
            timeout={{ enter: 1000, exit: 500 }}
            classNames="fade"

            onExited={node => {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'instant',
              });
            }}
          >
            <section className="route-section">
              <Switch location={location}>
                <Route exact path={"/"} component={() => (<AnaSayfa versiyon={versiyon} />)} />
                <Route exact path={"/iletisim"} component={() => (<Iletisim versiyon={versiyon} />)} />
                <Route exact path={"/aramasonuclari"} component={AramaSonuclari} />
                <Route exact path={"/egitimlerimiz"} component={() => (<Egitimlerimiz versiyon={versiyon} />)} />
                <Route exact path={"/egitmenlerimiz"} component={() => (<Egitmenlerimiz versiyon={versiyon} />)} />
                <Route exact path={"/blog"} component={() => (<Makalelerimiz versiyon={versiyon} />)} />

                {egitimlerimiz.map((m, i) => {
                  if (!m.gizli)
                    return (<Route key={"ana_egitimlerimiz_uzun_1"} exact path={"/egitimlerimiz/" + m.id} component={() => (<EgitimDetay id={m.id} isim={m.isim} egitmenid={m.egitmenid} egitmen={m.egitmen} egitmenunvan={m.egitmenunvan} egitmenresmi={m.egitmenresmi} derssayisi={m.derssayisi} sure={m.sure} anasayfa_aciklamakisa={m.anasayfa_aciklamakisa} anasayfa_aciklamatam={m.anasayfa_aciklamatam} detay_aciklama={m.detay_aciklama} resimgenis={m.resimgenis} resimdik={m.resimdik} video={m.video} metatitle={m.metatitle} metadescription={m.metadescription} metakeywords={m.metakeywords} versiyon={versiyon} />)} />);
                  else
                    return "";
                })}

                {egitimlerimiz.map((m, i) => {
                  if (!m.gizli)
                    return (<Route key={"ana_egitimlerimiz_kisa_1"} exact path={"/" + m.id} component={() => (<EgitimDetay id={m.id} isim={m.isim} egitmenid={m.egitmenid} egitmen={m.egitmen} egitmenunvan={m.egitmenunvan} egitmenresmi={m.egitmenresmi} derssayisi={m.derssayisi} sure={m.sure} anasayfa_aciklamakisa={m.anasayfa_aciklamakisa} anasayfa_aciklamatam={m.anasayfa_aciklamatam} detay_aciklama={m.detay_aciklama} resimgenis={m.resimgenis} resimdik={m.resimdik} video={m.video} metatitle={m.metatitle} metadescription={m.metadescription} metakeywords={m.metakeywords} versiyon={versiyon} />)} />);
                  else
                    return "";
                })}

                {egitmenlerimiz.map((m, i) => {
                  if (!m.gizli)
                    return (<Route key={"ana_egitmenlerimiz_uzun_1"} exact path={"/egitmenlerimiz/" + m.id} component={() => (<EgitmenDetay id={m.id} isim={m.isim} unvan={m.unvan} resim={m.resim} cv={m.cv} metatitle={m.metatitle} metadescription={m.metadescription} metakeywords={m.metakeywords} versiyon={versiyon} />)} />);
                  else
                    return "";
                })}

                {egitmenlerimiz.map((m, i) => {
                  if (!m.gizli)
                    return (<Route key={"ana_egitmenlerimiz_kisa_1"} exact path={"/" + m.id} component={() => (<EgitmenDetay id={m.id} isim={m.isim} unvan={m.unvan} resim={m.resim} cv={m.cv} metatitle={m.metatitle} metadescription={m.metadescription} metakeywords={m.metakeywords} versiyon={versiyon} />)} />);
                  else
                    return "";
                })}

                {kurumsal.map((m, i) => {
                  if (m.tur === "sayfa")
                    return (<Route key={"ana_kurumsal_uzun_1"} exact path={"/kurumsal/" + m.id} component={() => (<Kurumsal id={m.id} baslik={m.baslik} icerik={m.icerik} resim={m.resim} resimvarmi={m.resimvarmi} metatitle={m.metatitle} metadescription={m.metadescription} metakeywords={m.metakeywords} versiyon={versiyon} />)} />);
                  else
                    return "";
                })}

                {kurumsal.map((m, i) => {
                  if (m.tur === "sayfa")
                    return (<Route key={"ana_kurumsal_kisa_1"} exact path={"/" + m.id} component={() => (<Kurumsal id={m.id} baslik={m.baslik} icerik={m.icerik} resim={m.resim} resimvarmi={m.resimvarmi} metatitle={m.metatitle} metadescription={m.metadescription} metakeywords={m.metakeywords} versiyon={versiyon} />)} />);
                  else
                    return "";
                })}

                {makalelerimiz.map((m, i) => {
                  if (!m.gizli)
                    return (<Route key={"ana_blog_uzun_1"} exact path={"/blog/" + m.id} component={() => (<MakaleDetay id={m.id} baslik={m.baslik} yazar={m.yazar} tarih={m.tarih} icerik={m.icerik} resimvarmi={m.resimvarmi} resim={m.resim} metatitle={m.metatitle} metadescription={m.metadescription} metakeywords={m.metakeywords} versiyon={versiyon} />)} />);
                  else
                    return "";
                })}

                {makalelerimiz.map((m, i) => {
                  if (!m.gizli)
                    return (<Route key={"ana_blog_kisa_1"} exact path={"/" + m.id} component={() => (<MakaleDetay id={m.id} baslik={m.baslik} yazar={m.yazar} tarih={m.tarih} icerik={m.icerik} resimvarmi={m.resimvarmi} resim={m.resim} metatitle={m.metatitle} metadescription={m.metadescription} metakeywords={m.metakeywords} versiyon={versiyon} />)} />);
                  else
                    return "";
                })}

                <Route component={SayfaBulunamadi} />
              </Switch>
              <Enalt />
            </section>
          </CSSTransition>
        </TransitionGroup>

        <section>
          <FloatingActionButton />
        </section>

      </>
    );
  }
}

export default withRouter(Ana);
