import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Player, BigPlayButton } from 'video-react';
import "video-react/dist/video-react.css"; // import css
import { Link } from 'react-router-dom'

class EgitimListeOgesi extends React.Component {
  state = { detay: true };
  render() {
    const { detay } = this.state;

    return (
      <Row className="justify-content-center" itemScope itemType="http://schema.org/Course">
        <Col sm={12} className="py-md-2 py-40 pr-sm-0">
          <Row className="mx-0 justify-content-center" >
            <Col lg={5} className="d-lg-none d-block pl-0">
              <h4 className="pb-3" itemProp="name">{this.props.isim}</h4>
              <h5 className="pb-3" itemProp="creator">
                <Link style={{ color: "White" }} to={"/egitmenlerimiz/" + this.props.egitmenid}>{this.props.egitmen}</Link>
              </h5>
            </Col>
            <Col lg={5} d-lg={0} className="d-lg-none d-block pl-0 pr-0 pr-sm-3">
              {this.props.video === "yok" ?
                <div className="relative">
                  <img src={this.props.resimgenis + "?v=" + this.props.versiyon} className="img-fluid w-100" alt="yok" itemProp="image" />
                </div>
                :
                <Player aspectRatio="516:291"
                  playsInline
                  poster={this.props.resimgenis + "?v=" + this.props.versiyon}
                  src={this.props.video}>
                  <BigPlayButton position="center" />
                </Player>
              }

            </Col>
            <Col lg={5} className="d-lg-none d-block">
              <Row>
                <Col lg={12} className="pt-2 pb-3 pl-0 pr-ms-3 pr-0"     >
                  <div className="training-accordion">
                    <h3 className="gizle pt-10">{this.props.derssayisi} Ders <i>({Math.round(this.props.sure / 60)} Saat)</i></h3>
                    <h6>
                      {detay ?
                        <p className="kisa">
                          {this.props.anasayfa_aciklamakisa}... <span onClick={() => this.setState({ detay: !detay })} className="morelink">Daha Fazla ▼</span>
                        </p> :
                        <p className="uzun" itemProp="abstract" >
                          {this.props.anasayfa_aciklamatam}. <span onClick={() => this.setState({ detay: !detay })} className="morelink">Daha Az ▲</span>
                        </p>
                      }
                    </h6>
                    <div className="pt-3 mobile-width-100">
                      <Row>
                        <Col sm={12}>
                          <Button as={Link} to={"/egitimlerimiz/" + this.props.id} variant="danger">Eğitim Detayı</Button>{' '}
                          <Button variant="outline-light" onClick={() => { this.props.handleSimdiBasvur(true); this.props.handleSeciliOlan(this.props.isim) }}>Şimdi Başvur</Button>{' '}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={5} className="d-lg-block d-none pl-lg-0">
              <h4>{this.props.isim} </h4>
              <h5><Link to={this.props.egitmenid}>{this.props.egitmen}</Link></h5>
              <p>{this.props.egitmenunvan}</p>
              <h3 className="py-3 gizle">{this.props.derssayisi} Ders <i>({Math.round(this.props.sure / 60)} Saat)</i></h3>
              <h6>
                {detay ?
                  <p className="kisa">
                    {this.props.anasayfa_aciklamakisa} ... <span onClick={() => this.setState({ detay: !detay })} className="morelink">Daha Fazla ▼</span>
                  </p> :
                  <p className="uzun">
                    {this.props.anasayfa_aciklamatam}. <span onClick={() => this.setState({ detay: !detay })} className="morelink">Daha Az ▲</span>
                  </p>
                }
              </h6>
              <div className="pt-4">
                <Button as={Link} to={"/egitimlerimiz/" + this.props.id} variant="danger">Eğitim Detayı</Button>{' '}
                <Button variant="outline-light" onClick={() => { this.props.handleSimdiBasvur(true); this.props.handleSeciliOlan(this.props.isim) }}>Şimdi Başvur</Button>{' '}
              </div>
            </Col>
            <Col lg={5} className="d-lg-block d-none">
              {this.props.video === "yok" ?
                <div className="relative">
                  <img src={this.props.resimgenis + "?v=" + this.props.versiyon} className="img-fluid w-100" alt="yok" />
                </div>
                :
                <Player aspectRatio="516:291"
                  playsInline
                  poster={this.props.resimgenis + "?v=" + this.props.versiyon}
                  src={this.props.video}>
                  <BigPlayButton position="center" />
                </Player>
              }
            </Col>
          </Row>
        </Col>

      </Row>
    );
  }
}

export default EgitimListeOgesi;
