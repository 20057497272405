import { Container, Row, Col } from "react-bootstrap";
import React from "react";
import { Link } from 'react-router-dom'
import Helmet from "react-helmet";

export default class Makalelerimiz extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sayfaseo_isloaded: false,
      sayfaseo_error: "yok",
      sayfaseo: [],

      makalelerimiz_isloaded: false,
      makalelerimiz_error: "yok",
      makalelerimiz: [],
    };

    fetch(process.env.PUBLIC_URL + "/JSON/sayfaseo.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          sayfaseo_isloaded: true,
          sayfaseo: result
        });
      },
      (error) => {
        this.setState({
          sayfaseo_isloaded: true,
          sayfaseo_error: "Hata var " + error
        });
      }
    )
    fetch(process.env.PUBLIC_URL + "/JSON/makalelerimiz.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          makalelerimiz_isloaded: true,
          makalelerimiz: result.makalelerimiz
        });
      },
      (error) => {
        this.setState({
          makalelerimiz_isloaded: true,
          makalelerimiz_error: "Hata var " + error
        });
      }
    )
    this.aramaref = React.createRef();
  }
  resimyok(ev) {
    ev.target.src = 'images/makaleyok.png'
  }

  render() {
    const { makalelerimiz_isloaded, makalelerimiz_error, makalelerimiz,sayfaseo } = this.state;


    return (
      <>
        <Helmet>
        <title>{sayfaseo.makalelerimiz_metatitle}</title>
          <meta name="description" content={sayfaseo.makalelerimiz_metadescription} />
          <meta name="keywords" content={sayfaseo.makalelerimiz_metakeywords} />

          <meta property="og:title" content={sayfaseo.makalelerimiz_metatitle} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="%PUBLIC_URL%/blog" />
          <meta property="og:description" content={sayfaseo.makalelerimiz_metadescription} />
          <meta property="og:site_name" content="Anadolu Eğitim Akademisi" />
          <meta name="twitter:image:alt" content="Anadolu Eğitim Akademisi" />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>

        <section id="makalelerimizlistesi" className="bg">
          <Container>
            <Row className="justify-content-center">
              <Col sm={10} className="text-center">
                <h4>Blog</h4>
              </Col>
            </Row>

            {/* Eğitimlerimizi Listele */}
            {makalelerimiz_isloaded ? (<>

              <Row>
                {makalelerimiz.map((m, i) => {
                  if (!m.gizli) {
                    if (m.resimvarmi)
                    {
                      return (
                        <Col key={"Makalelerimiz_Liste" + i}  xs={12} sm={12} md={6} lg={6} xl={3} xxl={3} className="text-center">
                          <Link to={"/blog/" + m.id} className="yazirengibeyaz">
                            <div className="blogdiv" >
                              <div>
                                <img onError={this.resimyok} className="rounded" src={m.resim + "?v=" + this.props.versiyon} alt={m.isim} width="80%" />
                              </div>
                              <div>
                                {m.baslik}<br />
                                {m.yazar}
                              </div>
                            </div>
                          </Link>
                        </Col>
                      );
                      }
                      else
                      {
                        return (<>
                          <Col xs={12} sm={12} md={6} lg={6} xl={3} xxl={3} className="text-center">
                            <Link to={"/blog/" + m.id} className="yazirengibeyaz">
                              <div className="blogdiv" >
                                <div>
                                  <img className="rounded" src='images/makaleyok.png' alt='images/makaleyok.png' width="80%" />
                                </div>
                                <div>
                                  {m.baslik}<br />
                                  {m.yazar}
                                </div>
                              </div>
                            </Link>
                          </Col>
                        </>
                        );
                      }
                  }
                  else
                    return "";
                }


                )}

              </Row>
            </>) : (<>

              {makalelerimiz_error === "yok" ? (<>
                <div>Eğitimlerimiz modülü yükleniyor, lütfen bekleyin.</div>
              </>) : (<div className="hata">SSS Modülü yüklenemedi...</div>)}</>)}
          </Container>

        </section>
      </>
    );
  }
}
