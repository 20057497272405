import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from 'react-router-dom'
import Helmet from "react-helmet";

export default class Iletisim extends React.Component {
  constructor(props) {
    super(props);
    this.handleChangeTel = this.handleChangeTel.bind(this);

    this.state = {
      formgonder: false,
    };
    this.state = {
      tel: "",
      sayfaseo_isloaded: false,
      sayfaseo_error: "yok",
      sayfaseo: [],

      iletisim_isloaded: false,
      iletisim_error: "yok",
      iletisim: [],
    };
    fetch(process.env.PUBLIC_URL + "/JSON/sayfaseo.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          sayfaseo_isloaded: true,
          sayfaseo: result
        });
      },
      (error) => {
        this.setState({
          sayfaseo_isloaded: true,
          sayfaseo_error: "Hata var " + error
        });
      }
    )
    fetch(process.env.PUBLIC_URL + "/JSON/iletisim.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          iletisim_isloaded: true,
          iletisim: result
        });
      },
      (error) => {
        this.setState({
          iletisim_isloaded: true,
          iletisim_error: "Hata var " + error
        });
      }
    )
  }
  handleChangeTel(e) {
    console.log('I was triggered during render');
    if (e.target.value.match("^[0-9 ()+]*$") != null) {
      this.setState({ tel: e.target.value });
    }
  }
  render() {
    const { iletisim_isloaded, iletisim_error, iletisim, sayfaseo, tel } = this.state;
    const { formgonder } = this.state;
    return (
      <>
        <Helmet>
          <title>{sayfaseo.iletisim_metatitle}</title>
          <meta name="description" content={sayfaseo.iletisim_metadescription} />
          <meta name="keywords" content={sayfaseo.iletisim_metakeywords} />

          <meta property="og:title" content={sayfaseo.iletisim_metatitle} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="%PUBLIC_URL%/iletisim" />
          <meta property="og:description" content={sayfaseo.iletisim_metadescription} />
          <meta property="og:site_name" content="Anadolu Eğitim Akademisi" />
          <meta name="twitter:image:alt" content="Anadolu Eğitim Akademisi" />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <section id="egitimlerimizlistesi" className="bg">
          <Container>
            <Row className="justify-content-center">
              <Col sm={10} className="text-center">
                <h4>İletişim</h4>
              </Col>
            </Row>
            <Row>
              {iletisim.haritasrc !== ""?(<>
              <Col xs={12} md={6} className="iletisimharitaalti">
                <iframe title="de" src={iletisim.haritasrc} width="100%" height="100%" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
              </Col></>):(<></>)}

              <Col xs={12} md={iletisim.haritasrc !== ""?6:12}>
                <div className="mb-2">Adres: {iletisim.adres}  </div>
                <div className="mb-2">Telefon: <a href={iletisim.sabittelefonlink}> {iletisim.sabittelefon} </a> </div>
                <div className="mb-4">Eposta: <a href={"mailto://" + iletisim.eposta}> {iletisim.eposta}</a>  </div>
                <div className="iletisimdiv">Bizimle İletişime Geçin
                  <Form method="post" action="phpmailer/iletisim.php">
                    <Form.Group className="mb-3">
                      <Form.Label>Ad Soyad</Form.Label>
                      <Form.Control id="adsoyad" name="adsoyad" type="text" placeholder="Ad soyad giriniz" />
                    </Form.Group>
                    <Row>
                      <Col xs={12} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Eposta Adresi</Form.Label>
                          <Form.Control id="eposta" name="eposta" type="email" placeholder="Eposta adresinizi giriniz" />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Telefon:</Form.Label>
                          <Form.Control id="tel" name="tel" type="text" placeholder="Telefonunuzu giriniz." onChange={this.handleChangeTel} value={tel} required />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group className="mb-3">
                      <Form.Label>Konu</Form.Label>
                      <Form.Control id="konu" name="konu" as="textarea" rows={3} placeholder="Konuyu giriniz" />
                    </Form.Group>
                    <Row>
                      <Col xs={12} md={10}>
                        <Form.Group className="mb-3">
                          <Link to="/kisisel-verilerin-korunmasi">Kişisel Verilerin Korunması Metni Okumak İçin Tıklayın</Link>
                          <Form.Check id="secim" type="checkbox" label="KVKK ve Aydınlatma Metni Okudum / Onaylıyorum" onChange={() => this.setState({ formgonder: !formgonder })} />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={2}>
                        {formgonder ? (<Button variant="primary float-end" type="submit">Gönder</Button>) : (<Button variant="primary  float-end" type="submit" disabled>Gönder</Button>)}
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="hata">
            {iletisim_isloaded}
            {iletisim_error}
          </div>
        </section>
      </>
    );
  }
}
