import React from "react";
import EgitimAramaOgesi from "../API/egitimaramaogesi";
import { Row, Container, Form, Modal, Button } from "react-bootstrap"
import Helmet from "react-helmet";
export default class AramaSonuclari extends React.Component {

  constructor(props) {
    super(props);
    this.handleSimdiBasvur = this.handleSimdiBasvur.bind(this);
    this.handleSeciliOlan = this.handleSeciliOlan.bind(this);
    this.handleChangeTel = this.handleChangeTel.bind(this);

    this.state = {
      tel:"",
      simdibasvur: false,
      seciliOlan: "",
      isLoading: true,
      searchText: "",
      searchResults: [],

      egitimlerimiz_isloaded: false,
      egitimlerimiz_error: "yok",
      egitimlerimiz: [],
    };
    this.verilericek();
  }

  handleChangeTel(e){
    console.log('I was triggered during render');
    if(e.target.value.match("^[0-9 ()+]*$")!=null) {
      this.setState({tel:e.target.value});
    }
  }
  handleSimdiBasvur(param) {
    this.setState({ simdibasvur: param });
  }
  handleSeciliOlan(param) {
    this.setState({ seciliOlan: param });
  }

  handleSearch = () => {

    const { egitimlerimiz } = this.state;

    let searchText = this.props.location.state.searchText;

    let results = egitimlerimiz.filter(item => item.isim.toString().toLowerCase().includes(searchText.toString().toLowerCase()));
    results = results.concat(egitimlerimiz.filter(item => item.anasayfa_aciklamatam.toString().toLowerCase().includes(searchText.toString().toLowerCase())));
    results = [...new Set(results)];

    this.setState({
      isLoading: false,
      searchText: searchText,
      searchResults: results
    });
  };

  componentDidMount() {
    this.handleSearch();
  }

  componentDidUpdate(prevProps) {
    let prevSearch = prevProps.location.state.searchText;
    let newSearch = this.props.location.state.searchText;
    if (prevSearch !== newSearch) {
      this.handleSearch();
    }
  }

  verilericek() {
    fetch("JSON/egitimlerimiz.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          egitimlerimiz_isloaded: true,
          egitimlerimiz: result.egitimlerimiz
        });
        this.handleSearch();
      },
      (error) => {
        this.setState({
          egitimlerimiz_isloaded: true,
          egitimlerimiz_error: "Hata var " + error
        });
      }
    )
  }


  render() {
    const { simdibasvur, seciliOlan, egitimlerimiz,tel } = this.state;

    let toRender = this.state.isLoading ? (
      <h1>Aranıyor...</h1>
    ) : (
      <>
        <div className="mt-3 text-center">
          <h1>Arama Sonuçları:</h1>
          Aranan: "{this.state.searchText}"<br />
          {this.state.searchResults.length > 0 ? (<span>{this.state.searchResults.length} adet sonuç bulundu</span>) : (<p>Sonuç bulunamadı</p>)}
        </div>

        {this.state.searchResults.length > 0 ? (
          <>
            <Helmet>
              <title>Arama Sonuçları</title>
            </Helmet>
            <Modal show={simdibasvur} onHide={() => this.setState({ simdibasvur: false })} centered id="hemenbasvur" role="dialog" aria-labelledby="hemenbasvur" aria-hidden="true" >
              <Form method="post" action="\phpmailer\basvuruyap.php">
                <Modal.Header closeButton>
                  <Modal.Title><img alt="" src="/logo.png" height="30" className="d-inline-block align-top" /> <span className="buyukekranbrand">Anadolu Eğitim Akademisi</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="sagsolbosluk10">Eğitimlerimize sadece bir adım uzaklıktasınız. Aşağıdaki formu doldurduğunuzda detaylı bilgi için sizi arayacağız.</div>
                  <Form.Group className="mb-3" controlId="formBasicEdu">
                    <Form.Label>Başvurmak istediğiniz eğitim</Form.Label>
                    <Form.Select aria-label="Default select example" value={seciliOlan} id="egitim" name="egitim">
                      <option>Bir eğitim seçin</option>
                      {egitimlerimiz.map((item, i) =>
                      (
                        <option key={"AramaSonuclari_Egitimler" + i} value={item.isim}>{item.isim}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>İsim:</Form.Label>
                    <Form.Control id="isim" name="isim" type="text" placeholder="İsim ve soyisminizi giriniz." required />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Eposta:</Form.Label>
                    <Form.Control id="eposta" name="eposta" type="email" placeholder="Epostanızı giriniz." />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Telefon:</Form.Label>
                    <Form.Control id="tel" name="tel" type="text" placeholder="Telefonunuzu giriniz." onChange={this.handleChangeTel} value={tel} required />
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                  <Button variant="primary" type="submit">Gönder</Button>
                </Modal.Footer>
              </Form>
            </Modal>
            <Row className="m-3">
              {
                this.state.searchResults.map((m, i) => {
                  if (!m.gizli)
                    return <EgitimAramaOgesi key={"AramaSonuclari_EgitimAramaOgesi" + i} handleSimdiBasvur={this.handleSimdiBasvur} handleSeciliOlan={this.handleSeciliOlan} id={m.id} isim={m.isim} egitmen={m.egitmen} egitmenunvan={m.egitmenunvan} derssayisi={m.derssayisi} sure={m.sure} anasayfa_aciklamakisa={m.anasayfa_aciklamakisa} anasayfa_aciklamatam={m.anasayfa_aciklamatam} detay_aciklama={m.detay_aciklama} resimgenis={m.resimgenis} resimdik={m.resimdik} video={m.video} />;
                  else
                    return null;
                }
                )
              }
            </Row>
          </>

        ) : (
          <p></p>
        )}
      </>
    );

    return <Container>{toRender}</Container>;
  }
}
