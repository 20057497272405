import { Button, Container, Row, Col, Modal, Form } from "react-bootstrap";
import React from "react";
import { FaPlayCircle } from "react-icons/fa"
import { Player, BigPlayButton } from 'video-react';
import parse from 'html-react-parser'
import { Link } from 'react-router-dom'
import styled from "styled-components";
import Helmet from "react-helmet";
const SLink = styled(Link)`
  color: white;
  text-decoration: none;
  margin: 0.1rem;
  position: relative;
`;
export default class EgitimDetay extends React.Component {


  constructor(props) {
    super(props);
    this.handleSimdiBasvur = this.handleSimdiBasvur.bind(this);
    this.handleSeciliOlan = this.handleSeciliOlan.bind(this);
    this.handleChangeTel = this.handleChangeTel.bind(this);

    this.state = {
      tel: "",
      videogoster: false,
      simdibasvur: false,
      seciliOlan: "",
      egitimlerimiz_isloaded: false,
      egitimlerimiz_error: "yok",
      egitimlerimiz: []
    };
    this.verilericek();
  }
  handleChangeTel(e) {
    console.log('I was triggered during render');
    if (e.target.value.match("^[0-9 ()+]*$") != null) {
      this.setState({ tel: e.target.value });
    }
  }
  handleSimdiBasvur(param) {
    this.setState({ simdibasvur: param });
  }
  handleSeciliOlan(param) {
    this.setState({ seciliOlan: param });
  }

  verilericek() {
    fetch(process.env.PUBLIC_URL + "/JSON/egitimlerimiz.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          egitimlerimiz_isloaded: true,
          egitimlerimiz: result.egitimlerimiz
        });
      },
      (error) => {
        this.setState({
          egitimlerimiz_isloaded: true,
          egitimlerimiz_error: "Hata var " + error
        });
      }
    )
  }
  render() {
    const { videogoster, simdibasvur, seciliOlan, egitimlerimiz, tel } = this.state;


    return (
      <>
        <Helmet>
          <title>{this.props.metatitle}</title>
          <meta name="description" content={this.props.metadescription} />
          <meta name="keywords" content={this.props.metakeywords} />

          <meta property="og:title" content={this.props.metatitle} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={this.props.resimgenis + "?v=" + this.props.versiyon} />
          <meta property="og:url" content={"%PUBLIC_URL%/" + this.props.id} />
          <meta property="og:description" content={this.props.metadescription} />
          <meta property="og:site_name" content="Anadolu Eğitim Akademisi" />
          <meta name="twitter:image:alt" content="Anadolu Eğitim Akademisi" />
          <meta name="twitter:card" content="summary_large_image" />

        </Helmet>

        <Container className="container-xl pt-3" itemScope itemType="http://schema.org/Course">
          <Container>
            <Row>
              <Col xs={12} md={8}>
                <img src={"/" + this.props.resimgenis + "?v=" + this.props.versiyon} className="rounded yuzdeyuzgenislik" alt={this.props.isim} itemProp="image" />
                <div itemProp="abstract">{parse(this.props.detay_aciklama)}</div>
              </Col>
              <Col xs={12} md={4} className="dersplaniblok">
                <div className="text-center"><img src={"/" + this.props.egitmenresmi + "?v=" + this.props.versiyon} className="rounded egitmendetayresmi" alt={this.props.egitmen} align="center" /></div>
                <div itemProp="name"><h2 className="golgeli pt-3">{this.props.isim}</h2></div>
                <div className="pb-3" itemProp="creator"><SLink to={this.props.egitmenid}> {this.props.egitmenunvan} {this.props.egitmen}</SLink></div>

                {this.props.video === "yok" ? (<></>) : (<>
                  <div className="pb-3" >
                    <Button variant="danger" onClick={() => this.setState({ videogoster: true })} className="yuzdeyuzgenislik"><FaPlayCircle className="yazirengibeyaz" /> Eğitim Tanıtım Videosu</Button>
                  </div> </>)}
                <div className="pb-3">
                  <Button variant="danger" onClick={() => { this.setState({ simdibasvur: true, seciliOlan: this.props.isim }); }} className="yuzdeyuzgenislik" >Şimdi Başvur</Button>{' '}
                </div>
                <div>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
        <Modal show={simdibasvur} onHide={() => this.setState({ simdibasvur: false })} centered id="hemenbasvur" role="dialog" aria-labelledby="hemenbasvur" aria-hidden="true" >
          <Form method="post" action="\phpmailer\basvuruyap.php">
            <Modal.Header closeButton>
              <Modal.Title><img alt="" src="/logo.png" height="30" className="d-inline-block align-top" /> <span className="buyukekranbrand">Anadolu Eğitim Akademisi</span></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="sagsolbosluk10">Eğitimlerimize sadece bir adım uzaklıktasınız. Aşağıdaki formu doldurduğunuzda detaylı bilgi için sizi arayacağız.</div>
              <Form.Group className="mb-3" controlId="formBasicEdu">
                <Form.Label>Başvurmak istediğiniz eğitim</Form.Label>
                <Form.Select aria-label="Default select example" value={seciliOlan} id="egitim" name="egitim">
                  <option>Bir eğitim seçin</option>
                  {egitimlerimiz.map((item, i) =>
                  (
                    <option key={"EgitimDetay_BirEgitimSecin" + i} value={item.isim}>{item.isim}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>İsim:</Form.Label>
                <Form.Control id="isim" name="isim" type="text" placeholder="İsim ve soyisminizi giriniz." required />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Eposta:</Form.Label>
                <Form.Control id="eposta" name="eposta" type="email" placeholder="Epostanızı giriniz." />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Telefon:</Form.Label>
                <Form.Control id="tel" name="tel" type="text" placeholder="Telefonunuzu giriniz." onChange={this.handleChangeTel} value={tel} required />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
              <Button variant="primary" type="submit">Gönder</Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <Modal show={videogoster} size="lg" onHide={() => this.setState({ videogoster: false })} centered>
          <Modal.Header closeButton>
            <Modal.Title>{this.props.isim} Tanıtım Videosu</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Player aspectRatio="516:291"
              playsInline
              poster={this.props.resimgenis + "?v=" + this.props.versiyon}
              src={this.props.video}>
              <BigPlayButton position="center" />
            </Player></Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.setState({ videogoster: false })}>Kapat</Button>
          </Modal.Footer>
        </Modal>

      </>
    );
  }
}
