import React from "react";
import { Carousel, Row, Col, Button, Accordion, Container, Modal, Form } from "react-bootstrap";
import Slider from "react-slick";
import { Link } from 'react-router-dom'

import EgitimListeOgesi from "../API/egitimlisteogesi";
import Kndlisteogesi from "../API/knd";
import Helmet from "react-helmet";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class AnaSayfa extends React.Component {

  constructor(props) {
    super(props);
    this.handleSimdiBasvur = this.handleSimdiBasvur.bind(this);
    this.handleSeciliOlan = this.handleSeciliOlan.bind(this);
    this.handleChangeTel = this.handleChangeTel.bind(this);

    this.state = {
      tel: "",
      height: "100vh",
      simdibasvur: false,
      seciliOlan: "",

      sayfaseo_isloaded: false,
      sayfaseo_error: "yok",
      sayfaseo: [],

      egitimlerimiz_isloaded: false,
      egitimlerimiz_error: "yok",
      egitimlerimiz: [],

      sss_isloaded: false,
      sss_error: "yok",
      sss: [],

      sertifikalar_isloaded: false,
      sertifikalar_error: "yok",
      sertifikalar: [],

      knd_isloaded: false,
      knd_error: "yok",
      knd: []
    };
    

  }
  handleSimdiBasvur(param) {
    this.setState({ simdibasvur: param });
  }
  handleSeciliOlan(param) {
    this.setState({ seciliOlan: param });
  }
  handleChangeTel(e) {
    console.log('I was triggered during render');
    if (e.target.value.match("^[0-9 ()+]*$") != null) {
      this.setState({ tel: e.target.value });
    }
  }
  handleResize = e => {
    var x = 100;
    if (window.innerWidth > 991) x = 55;
    const height = window.innerHeight - x;
    this.setState(prevState => {
      return {
        height
      };
    });
  };
  verilericek() {
    fetch("/JSON/sayfaseo.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          sayfaseo_isloaded: true,
          sayfaseo: result
        });
      },
      (error) => {
        this.setState({
          sayfaseo_isloaded: true,
          sayfaseo_error: "Hata var " + error
        });
      }
    )

    fetch("/JSON/egitimlerimiz.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          egitimlerimiz_isloaded: true,
          egitimlerimiz: result.egitimlerimiz
        });
      },
      (error) => {
        this.setState({
          egitimlerimiz_isloaded: true,
          egitimlerimiz_error: "Hata var " + error
        });
      }
    )
    fetch("/JSON/sertifikalar.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          sertifikalar_isloaded: true,
          sertifikalar: result.sertifikalarimiz
        });
      },
      (error) => {
        this.setState({
          sertifikalar_isloaded: true,
          sertifikalar_error: "Hata var " + error
        });
      }
    )
    fetch("/JSON/sss.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          sss_isloaded: true,
          sss: result.sikcasorulansorular
        });
      },
      (error) => {
        this.setState({
          sss_isloaded: true,
          sss_error: "Hata var " + error
        });
      }
    )
    fetch("/JSON/knd.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          knd_isloaded: true,
          knd: result.kullanicilarnediyor
        });
      },
      (error) => {
        this.setState({
          knd_isloaded: true,
          knd_error: "Hata var " + error
        });
      }
    )
  }



  componentDidMount() {
    this.verilericek();
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const { simdibasvur, seciliOlan, height, egitimlerimiz_isloaded, egitimlerimiz_error, egitimlerimiz, sertifikalar_isloaded, sertifikalar_error, sertifikalar, sss_isloaded, sss_error, sss, knd_isloaded, knd_error, knd, sayfaseo, tel } = this.state;
    var settings1 = { dots: true, infinite: true, speed: 500, autoplay: true, autoplaySpeed: 3000, slidesToShow: 1, slidesToScroll: 1 };
    var settings3 = { dots: false, infinite: true, speed: 500, autoplay: true, autoplaySpeed: 3000, slidesToShow: 3, slidesToScroll: 1 };
    var settings6 = { dots: true, infinite: true, speed: 500, autoplay: true, autoplaySpeed: 3000, slidesToShow: 6, slidesToScroll: 1 };
    return (
      <>
        {/* anasayfa carousel */}
        <Helmet>
          <title>{sayfaseo.anasayfa_metatitle}</title>
          <meta name="description" content={sayfaseo.anasayfa_metadescription} />
          <meta name="keywords" content={sayfaseo.anasayfa_metakeywords} />

          <meta property="og:title" content={sayfaseo.anasayfa_metatitle} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content="%PUBLIC_URL%/images/bizkimiz.png" />
          <meta property="og:url" content="%PUBLIC_URL%/index.html" />
          <meta property="og:description" content={sayfaseo.anasayfa_metadescription} />
          <meta property="og:site_name" content="Anadolu Eğitim Akademisi" />
          <meta name="twitter:image:alt" content="Anadolu Eğitim Akademisi" />
          <meta name="twitter:card" content="summary_large_image" />

        </Helmet>
        <section id="carousel">
          <Container>
            <Row>
              <Col>
                <Carousel>
                  {egitimlerimiz.map((item, i) => {
                    if (!item.gizli) return (
                      <Carousel.Item key={"AnaSayfa_Carousel_Egitimlerimiz" + i}>
                        <div className="resimyukseklikdivi" style={{ height: height }}>
                          <div className="resimcerceve"></div>
                          <img className="d-none d-sm-block w-100 resim" src={item.resimgenis + "?v=" + this.props.versiyon} alt="Geniş Resim" />
                          <img className="d-block d-sm-none w-100 resim" src={item.resimdik + "?v=" + this.props.versiyon} alt="Dar Resim" />
                        </div>
                        <Carousel.Caption className="caruselbaslik">
                          <Row>
                            <Col xs={12}>
                              <h1 className="golgeli">{item.isim}</h1>
                              <p className="golgeli gizle">{item.derssayisi} ders ({item.sure} saat)</p>
                              <p className="golgeli">{item.egitmen}<br />{item.egitmenunvan}</p>
                            </Col>
                          </Row>
                          <Row className="d-none d-sm-block">
                            <Col xs={12}>
                              <Button as={Link} to={"/egitimlerimiz/" + item.id} variant="danger" >Eğitim Detayı</Button>{' '}
                              <Button variant="outline-light" onClick={() => { this.setState({ simdibasvur: true, seciliOlan: item.isim }); }} >Şimdi Başvur</Button>{' '}
                            </Col>
                          </Row>
                          <Row className="d-block d-sm-none p-1">
                            <Button as={Link} to={"/egitimlerimiz/" + item.id} className="xs-12" variant="danger">Eğitim Detayı</Button>{' '}
                          </Row>
                          <Row className="d-block d-sm-none p-1">
                            <Button variant="outline-light" onClick={() => { this.setState({ simdibasvur: true, seciliOlan: item.isim }); }} >Şimdi Başvur</Button>{' '}
                          </Row>
                        </Carousel.Caption>
                      </Carousel.Item>);
                    else
                      return "";
                  })
                  }
                </Carousel>

              </Col>
            </Row>
          </Container>
          <Modal show={simdibasvur} onHide={() => this.setState({ simdibasvur: false })} centered id="hemenbasvur" role="dialog" aria-labelledby="hemenbasvur" aria-hidden="true" >
            <Form method="post" action="\phpmailer\basvuruyap.php" >
              <Modal.Header closeButton>
                <Modal.Title><img alt="" src="/logo.png" height="30" className="d-inline-block align-top" /> <span className="buyukekranbrand">Anadolu Eğitim Akademisi</span></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="sagsolbosluk10">Eğitimlerimize sadece bir adım uzaklıktasınız. Aşağıdaki formu doldurduğunuzda detaylı bilgi için sizi arayacağız.</div>
                <Form.Group className="mb-3">
                  <Form.Label>Başvurmak istediğiniz eğitim</Form.Label>
                  <Form.Select aria-label="Default select example" defaultValue={seciliOlan} id="egitim" name="egitim">
                    <option>Bir eğitim seçin</option>
                    {egitimlerimiz.map((item, i) =>
                    (
                      <option key={"AnaSayfa_Bir_Egitim_Sec" + i} value={item.isim}>{item.isim}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>İsim:</Form.Label>
                  <Form.Control id="isim" name="isim" type="text" placeholder="İsim ve soyisminizi giriniz." required />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Eposta:</Form.Label>
                  <Form.Control id="eposta" name="eposta" type="email" placeholder="Epostanızı giriniz." required />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Telefon:</Form.Label>
                  <Form.Control id="tel" name="tel" type="text" placeholder="Telefonunuzu giriniz." onChange={this.handleChangeTel} value={tel} required />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer className="justify-content-center">
                <Button variant="primary" type="submit" >Gönder</Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </section>
        <section id="sertifikalar" className="bg">
          <Container>
            {sertifikalar_isloaded ? (<>
              <Row className="d-none d-lg-block">
                <Col>
                  <Slider {...settings6}>
                    {sertifikalar.map((sertifika, i) => (
                      <div key={"AnaSayfa_Sertifikalar6" + i}>
                        <img className="sertifikaresmi" src={sertifika.resim} alt={sertifika.sertifikaismi} />
                      </div>
                    ))}
                  </Slider>
                </Col>
              </Row>
            </>) : (<>
              {sertifikalar_error === "yok" ? (<>
                <div>Sertifikalar yükleniyor</div>
              </>) : (<div className="hata">Sertifikalar modülü yüklenemedi...</div>)}</>)}

            <Row className="d-block d-lg-none  justify-content-center " >
              <Col>
                <Slider {...settings3}>
                  {sertifikalar.map((sertifika, i) => (
                    <div key={"AnaSayfa_Sertifikalar3" + i}>
                      <img className="sertifikaresmi2" src={sertifika.resim} alt={sertifika.sertifikaismi} />
                    </div>
                  ))}
                </Slider>
              </Col>
            </Row>
          </Container>

        </section>
        {/* anasayfa size özel eğitimler */}
        <section id="sizeozelegitimlerimiz" >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-10">
                <div className="row align-items-center d-flex">

                  <div className="col-lg-5 py-2">
                    <div className="row">
                      <div className="col-sm-12 new-trainings">
                        <h1>Size Özel Eğitimler</h1>
                        <div className="col-lg-7 py-2 pr-sm-0 d-lg-none d-block">
                          <img className="w-100" src="/images/personeller.jpg" alt="res" />
                        </div>

                        <p className="py-sm-4 py-3">Birçok alanda Türkiye'nin en iyi eğitmenlerinden sertifikalı eğitim almaya hazır mısınız?</p>
                        <p>
                          <span className="m-2"><Button variant="danger" onClick={() => { this.setState({ simdibasvur: true, seciliOlan: "" }); }} >Şimdi Başvur</Button>{' '}</span>
                          <span className="m-2"><Button variant="outline-light" as={Link} to="/egitimlerimiz">Tüm Eğitimlerimiz</Button>{' '}</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-7 py-2 pr-sm-0 d-lg-block d-none">
                    <img className="w-100" src="/images/personeller.jpg" alt="res" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ana sayfa eğitimlerimiz */}
        <section id="egitimlerimizlistesi" className="bg">
          <Container>


            <Row className="justify-content-center">

              <Col sm={10} className="text-center">
                <h4>Eğitimlerimiz</h4>
                {/*eğitimlerimiz başlığı 
                <h5 className="gizle">Derslerin ortalama süresi {Math.round(egitimlerimiz.reduce((stoplam, { sure }) => stoplam + sure, 0) / egitimlerimiz.reduce((dstoplam, { derssayisi }) => dstoplam + derssayisi, 0))} dakika.</h5>
                <h5 className="gizle">Toplamda {egitimlerimiz.reduce((dstoplam, { derssayisi }) => dstoplam + derssayisi, 0)} ders.</h5>
                <h5 className="gizle">Sunduğumuz {egitimlerimiz.length} eğitimden ilgilendiğiniz eğitimi seçin</h5>*/}
              </Col>
            </Row>

            {/* Eğitimlerimizi Listele */}
            {egitimlerimiz_isloaded ? (<>

              {egitimlerimiz.map((m, i) => {
                if (!m.gizli) return (
                  <Row key={"AnaSayfa_Egitim_Listele" + i}>
                    <Row className="justify-content-center">
                      <Col lg={10}>
                        <div className="egitimlerimizcizgidiv" />
                      </Col>
                    </Row>
                    <EgitimListeOgesi handleSimdiBasvur={this.handleSimdiBasvur} handleSeciliOlan={this.handleSeciliOlan} id={m.id} isim={m.isim} egitmenid={m.egitmenid} egitmen={m.egitmen} egitmenunvan={m.egitmenunvan} egitmenresmi={m.egitmenresmi} derssayisi={m.derssayisi} sure={m.sure} anasayfa_aciklamakisa={m.anasayfa_aciklamakisa} anasayfa_aciklamatam={m.anasayfa_aciklamatam} detay_aciklama={m.detay_aciklama} resimgenis={m.resimgenis} resimdik={m.resimdik} video={m.video} versiyon={this.props.versiyon} />
                  </Row>
                ); else return "";
              }
              )}

            </>) : (<>
              {egitimlerimiz_error === "yok" ? (<>
                <div>Eğitimler yükleniyor, lütfen bekleyin.</div>
              </>) : (<div className="hata">SSS Modülü yüklenemedi...</div>)}</>)}

            <Row>
              <Col sm={12} className="text-center pt-4">
                <Button variant="outline-light" as={Link} to="/egitimlerimiz"><h3 className="p-2">TÜM EĞİTİMLERİMİZ</h3></Button>{' '}
              </Col>
            </Row>
          </Container>

        </section>

        {/* ana sayfa sıkça sorulan sorular */}
        {sss_isloaded ? (<>
          <section id="sss" className="gizle">
            <Container>
              <Row className="justify-content-center">
                <Col xl={12} className="text-center">
                  <h2>Sıkça Sorulan Sorular</h2>
                </Col>
                {
                  sss.map(((kategoriler, j) => {
                    return (
                      <Col key={"AnaSayfa_SSS_Kategoriler_Listele" + j} xs={12} sm={12} md={12} lg={8}>
                        <h2 className="p-2 pt-4">{kategoriler.kategoriadi}</h2>
                        {kategoriler.icerik.map((sss_i, i) =>
                        (
                          <Accordion key={"AnaSayfa_SSS_Listele" + i}>
                            <Accordion.Item eventkey={i}>
                              <Accordion.Header>
                                {sss_i.baslik}
                              </Accordion.Header>
                              <Accordion.Body >
                                {sss_i.aciklama}
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        ))}
                      </Col>
                    );
                  }))
                }
              </Row>

            </Container>

          </section>
        </>) : (<>
          {sss_error === "yok" ? (<>
            <div>SSS Modülü yükleniyor...</div>
          </>) : (<div className="hata">SSS Modülü yüklenemedi...</div>)}</>)}


        {/*kursiyerlerimiz ne diyor pc*/}
        <section id="kursiyerlerimiznediyor" className="bg gizle" >
          <Container>
            <Row>
              <Col xl={12} className="text-center">
                <h2>Kursiyerlerimiz ne diyor?</h2>
              </Col>
            </Row>
            <Row>
              <Col xl={12} className="text-center">
                {knd_error === "yok" ? (<>
                  <Slider {...settings1} adaptiveHeight={true} className="d-block d-lg-none justify-content-center">
                    {knd.map((icerik, i) => (
                      <Kndlisteogesi key={"AnaSayfa_KND1" + i} kursiyer={icerik.kursiyer} unvan={icerik.unvan} il={icerik.il} aciklamakisa={icerik.aciklamakisa} aciklamatam={icerik.aciklamatam} resim={icerik.resim} />
                    ))}
                  </Slider>
                </>) : (<>
                  <div>
                    HATA {knd_error}
                  </div>
                </>)}

                {knd_isloaded ? (<>
                  <Slider {...settings3} adaptiveHeight={true} className="d-none d-lg-block">
                    {knd.map((icerik, i) => (
                      <Kndlisteogesi key={"AnaSayfa_KND3" + i} kursiyer={icerik.kursiyer} unvan={icerik.unvan} il={icerik.il} aciklamakisa={icerik.aciklamakisa} aciklamatam={icerik.aciklamatam} resim={icerik.resim} />
                    ))}
                  </Slider>
                </>) : (<>
                  {knd_error === "yok" ? (<><div>Kursiyerlerimiz ne diyor? modülü yükleniyor...</div></>)
                    : (<div className="hata">Kursiyerlerimiz ne diyor? modülü yüklenemedi...</div>)}</>)}
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}
