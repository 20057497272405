import React from "react";
import {Col, Button } from "react-bootstrap";
import "video-react/dist/video-react.css"; // import css
import { Link } from 'react-router-dom'


class EgitimAramaOgesi extends React.Component {
  render() {
    return (
      <Col lg={4} className="egitimaramaogesicol">
        <div className="text-center egitimaramaogesikutu">
        <h4>{this.props.isim} </h4>
        <h5>{this.props.egitmen}</h5>
        <p>{this.props.egitmenunvan}</p>
        <h3 className="py-3 gizle">{this.props.derssayisi} Ders <i>({Math.round(this.props.sure / 60)} Saat)</i></h3>
        <div className="pt-4">
          <Button as={Link} to={"egitimlerimiz/" + this.props.id} variant="danger">Eğitim Detayı</Button>{' '}
          <Button variant="outline-light" onClick={() => { this.props.handleSimdiBasvur(true); this.props.handleSeciliOlan(this.props.isim) }}>Şimdi Başvur</Button>{' '}
        </div>
        </div>
      </Col>
    );
  }
}

export default EgitimAramaOgesi;
