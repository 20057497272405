import { Button, Container, Row, Col, Form, Modal } from "react-bootstrap";
import React from "react";
import EgitimListeOgesi from "../API/egitimlisteogesi";
import Helmet from "react-helmet";
import { Link } from 'react-router-dom'

export default class Egitimlerimiz extends React.Component {
  constructor(props) {
    super(props);
    this.handleSimdiBasvur = this.handleSimdiBasvur.bind(this);
    this.handleSeciliOlan = this.handleSeciliOlan.bind(this);
    this.handleChangeTel = this.handleChangeTel.bind(this);

    this.state = {
      tel:"",
      searchText: "",
      simdibasvur: false,
      seciliOlan: "",
      aramagorunumu: false,

      sayfaseo_isloaded: false,
      sayfaseo_error: "yok",
      sayfaseo: [],

      egitimlerimiz_isloaded: false,
      egitimlerimiz_error: "yok",
      egitimlerimiz: []
    };
    fetch(process.env.PUBLIC_URL + "/JSON/sayfaseo.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          sayfaseo_isloaded: true,
          sayfaseo: result
        });
      },
      (error) => {
        this.setState({
          sayfaseo_isloaded: true,
          sayfaseo_error: "Hata var " + error
        });
      }
    )

    fetch(process.env.PUBLIC_URL + "/JSON/egitimlerimiz.json").then(res => res.json()).then(
      (result) => {
        this.setState({
          egitimlerimiz_isloaded: true,
          egitimlerimiz: result.egitimlerimiz
        });
      },
      (error) => {
        this.setState({
          egitimlerimiz_isloaded: true,
          egitimlerimiz_error: "Hata var " + error
        });
      }
    )
    this.aramaref = React.createRef();
  }
  handleChangeTel(e){
    console.log('I was triggered during render');
    if(e.target.value.match("^[0-9 ()+]*$")!=null) {
      this.setState({tel:e.target.value});
    }
  }

  handleSimdiBasvur(param) {
    this.setState({ simdibasvur: param });
  }
  handleSeciliOlan(param) {
    this.setState({ seciliOlan: param });
  }

  render() {
    const { simdibasvur, seciliOlan, egitimlerimiz_isloaded, egitimlerimiz_error, egitimlerimiz, sayfaseo,tel } = this.state;

    return (
      <>
        <Helmet>
          <title>{sayfaseo.egitimlerimiz_metatitle}</title>
          <meta name="description" content={sayfaseo.egitimlerimiz_metadescription} />
          <meta name="keywords" content={sayfaseo.egitimlerimiz_metakeywords} />

          <meta property="og:title" content={sayfaseo.egitimlerimiz_metatitle} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="%PUBLIC_URL%/egitimler" />
          <meta property="og:description" content={sayfaseo.egitimlerimiz_metadescription} />
          <meta property="og:site_name" content="Anadolu Eğitim Akademisi" />
          <meta name="twitter:image:alt" content="Anadolu Eğitim Akademisi" />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <section id="egitimlerimizlistesi" className="bg">
          <Container>


            <Row className="justify-content-center">

              <Col sm={10} className="text-center">
                <h4>Eğitimlerimiz</h4>
                {/*eğitimlerimiz başlığı 
                <h5 className="gizle">Derslerin ortalama süresi {Math.round(egitimlerimiz.reduce((stoplam, { sure }) => stoplam + sure, 0) / egitimlerimiz.reduce((dstoplam, { derssayisi }) => dstoplam + derssayisi, 0))} dakika.</h5>
                <h5 className="gizle">Toplamda {egitimlerimiz.reduce((dstoplam, { derssayisi }) => dstoplam + derssayisi, 0)} ders.</h5>
                <h5 className="gizle">Sunduğumuz {egitimlerimiz.length} eğitimden ilgilendiğiniz eğitimi seçin</h5>*/}
              </Col>
            </Row>

            {/* Eğitimlerimizi Listele */}
            {egitimlerimiz_isloaded ? (<>

              {egitimlerimiz.map((m, i) => {
                if (!m.gizli) return (
                  <Row key={"egitimlerimiz_egitim_listele" + i}>
                    <Row className="justify-content-center">
                      <Col lg={10}>
                        <div className="egitimlerimizcizgidiv" />
                      </Col>
                    </Row>
                    <EgitimListeOgesi key={"eelo" + i} handleSimdiBasvur={this.handleSimdiBasvur} handleSeciliOlan={this.handleSeciliOlan} id={m.id} isim={m.isim} egitmen={m.egitmen} egitmenid={m.egitmenid} egitmenunvan={m.egitmenunvan} derssayisi={m.derssayisi} sure={m.sure} anasayfa_aciklamakisa={m.anasayfa_aciklamakisa} anasayfa_aciklamatam={m.anasayfa_aciklamatam} detay_aciklama={m.detay_aciklama} resimgenis={m.resimgenis} resimdik={m.resimdik} video={m.video}  />
                  </Row>
                );
                else
                  return "";
              })}

            </>) : (<>

              {egitimlerimiz_error === "yok" ? (<>
                <div>Eğitimler yükleniyor, lütfen bekleyin.</div>
              </>) : (<div className="hata">SSS Modülü yüklenemedi...</div>)}</>)}

            <Row>
              <Col sm={12} className="text-center pt-4">
                <Button variant="outline-light" as={Link} to="/egitimlerimiz" ><h3 className="p-2">TÜM EĞİTİMLERİMİZ</h3></Button>{' '}
              </Col>
            </Row>
            <Modal show={simdibasvur} onHide={() => this.setState({ simdibasvur: false })} centered id="hemenbasvur" role="dialog" aria-labelledby="hemenbasvur" aria-hidden="true" >
              <Form method="post" action="\phpmailer\basvuruyap.php">
                <Modal.Header closeButton>
                  <Modal.Title><img alt="" src="logo.png" height="30" className="d-inline-block align-top" /> <span className="buyukekranbrand">Anadolu Eğitim Akademisi</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="sagsolbosluk10">Eğitimlerimize sadece bir adım uzaklıktasınız. Aşağıdaki formu doldurduğunuzda detaylı bilgi için sizi arayacağız.</div>
                  <Form.Group className="mb-3" controlId="formBasicEdu">
                    <Form.Label>Başvurmak istediğiniz eğitim</Form.Label>
                    <Form.Select aria-label="Default select example" value={seciliOlan} id="egitim" name="egitim">
                      <option>Bir eğitim seçin</option>
                      {egitimlerimiz.map((item, i) =>
                      (
                        <option key={"Egitimlerimiz_BirEgitimSecin" + i} value={item.isim}>{item.isim}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>İsim:</Form.Label>
                    <Form.Control id="isim" name="isim" type="text" placeholder="İsim ve soyisminizi giriniz." required />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Eposta:</Form.Label>
                    <Form.Control id="eposta" name="eposta" type="email" placeholder="Epostanızı giriniz." />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Telefon:</Form.Label>
                    <Form.Control id="tel" name="tel" type="text" placeholder="Telefonunuzu giriniz." onChange={this.handleChangeTel} value={tel} required />
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                  <Button variant="primary" type="submit">Gönder</Button>
                </Modal.Footer>
              </Form>
            </Modal>
          </Container>

        </section>
      </>
    );
  }
}
