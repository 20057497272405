import { Container, Row, Col } from "react-bootstrap";
import React from "react";
import parse from 'html-react-parser'
import Helmet from "react-helmet";

export default class MakaleDetay extends React.Component {
  state = { videogoster: false };

  resimyok(ev) {
    ev.target.src = '/images/makaleyok.png'
  }

  render() {
    return (
      <>
        <Helmet>
          <title>{this.props.metatitle}</title>
          <meta name="description" content={this.props.metadescription} />
          <meta name="keywords" content={this.props.metakeywords} />

          <meta property="og:title" content={this.props.metatitle} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={this.props.resim + "?v=" + this.props.versiyon} />
          <meta property="og:url" content={"%PUBLIC_URL%/" + this.props.id} />
          <meta property="og:description" content={this.props.metadescription} />
          <meta property="og:site_name" content="Anadolu Eğitim Akademisi" />
          <meta name="twitter:image:alt" content="Anadolu Eğitim Akademisi" />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>

        <Container className="container-xl pt-3" itemScope itemType="http://schema.org/Person">

          <Row className="pb-3 justify-content-center">
            <Col className="pb-3">
              <h1 itemProp="name">{this.props.baslik}</h1>
            </Col>
          </Row>
          <Row className="pb-3 justify-content-center">
            <Col className="pb-3">
              {
                (() => {
                  if (this.props.resimvarmi) {
                    return (
                      <img onError={this.resimyok} itemProp="image" src={"/" + this.props.resim + "?v=" + this.props.versiyon} className="rounded yuzdeelligenislik sagayasli sagsolbosluk30" alt={this.props.baslik} />
                    )
                  }
                })()
              }
              {parse(this.props.icerik)} </Col>
          </Row>
          <Row className="pb-3 justify-content-center">
            <Col className="pb-3">
              <h2>{this.props.yazar}</h2>
              <h5>{this.props.tarih}</h5>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
